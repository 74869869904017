import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Spotify" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
      <meta http-equiv="refresh" content="0; URL='https://open.spotify.com/artist/5es0ckctGcRgDY4n0F2erN'" />
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
fluid(maxWidth: 1360) {
  ...GatsbyImageSharpFluid
}
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
